:root {
  --main-bg-color: #ffffff;
  --secondary-bg-color: #005EB8;
  --selected-bg-color: #004a91;
  --tertiary-bg-color: #f5f5f5;
  --main-btn-color: #005EB8;
  --secondary-btn-color: #90C84C;
  --selected-secondary-btn-color: #76a340;
  --main-font-color: #343434;
  --secondary-font-color: #ffffff;
}

#main-container {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.width-is-min-content {
  width: min-content;
}

.width-is-max-content {
  width: max-content;
}

.items-are-center-aligned {
  align-items: center;
}

.content-is-center-justified {
  justify-content: center;
}

.content-is-flex-end-justified {
  justify-content: flex-end;
}

p, h1, h2, h3, h4 {
  margin-top: auto;
  margin-bottom: auto !important;
  color: var(--main-font-color);
}

.auto-margin {
  margin: auto;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

/* bootstrap custom */
.btn.btn-primary {
  background-color: var(--main-btn-color);
  border-color: var(--main-btn-color);
}

.btn.btn-primary:hover {
  background-color: var(--selected-bg-color);
  border-color: var(--selected-bg-color);
}

.btn.btn-secondary {
  background-color: var(--secondary-btn-color);
  border-color: var(--secondary-btn-color);
}

.btn.btn-secondary:hover {
  background-color: var(--selected-secondary-btn-color);
  border-color: var(--selected-secondary-btn-color);
}

.text-primary-cus {
 color: var(--selected-bg-color);
}

.text-secondary-cus {
  color: var(--selected-secondary-btn-color);
}