.draggable, .draggable > * {
    cursor: move;
    pointer-events: bounding-box;
}

.EvaluationContainer {
    display: flex;
    flex-direction: row;
    position: fixed;
    align-items: center;
    justify-content: center;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0, 0, .5);
    z-index: 100;
}

.EvaluationDate {
    min-width: 20vw;
    min-height: 20vh;
    position: fixed;
    z-index: 100;
    text-align: center;
    vertical-align: middle;
    background: white;
    padding: 1.5em;
    /*box-shadow: 100vw 100vh 100vw 100vw black;*/
}

.ExerciceName {
    font-size: 1.5em;
    font-weight: bold;
    padding-bottom: .5em;
}

.EvaluationFields {
    display: grid;
    grid-column-gap: .5em;
    grid-template-columns: auto auto;
    align-items: center;
}

.EvaluationFields > * {
    padding: .1em .1em .1em 1em;
    text-align: left;
}

.EvaluationFieldName {
    text-align: left;
    font-weight: bold;
}

.Event.happened > * {
    opacity: .5;
}

.DropdownArea {
    padding-left: 0;
}
