.UserSearch {
    width: max-content;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 3.5rem;
    align-items: center;
}

.UserSearch > * {
    padding: 0 .5rem;
    min-width: max-content;
    max-width: 100%;
}

.UserSearch td, th {
    white-space: nowrap;
}

.UserSearch th {
font-weight: bold;
}

.userSearchForm {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    border: 1px solid var(--main-font-color);
    padding: 0.5rem;
}

.userSearchForm .btn {
    margin: 1rem;
}

.userSearchForm .container {
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}

.userSearchForm .container>* {
    align-items: center;
    justify-content: center;
    margin: 1rem 1rem;
}

.userSearchForm .container>*>* {
    margin: auto 1rem;
}
