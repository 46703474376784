.RoleElements {
    display: flex;
    justify-items: center;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    align-content: space-between;
}

.RoleElements * {
    margin: auto .5rem;
}