.ReferentialUploadContainer {
    margin: 3.5rem;
}

.ReferentialUploadContainer input[type='file'] {
    display: none;
}

.ReferentialUploadContainer input, label, .ClearIcon {
    cursor: pointer;
}

.ReferentialUploadContainer .FileName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ReferentialUploadContainer .FileName > * {
    margin: 0 .5vw;
}

.ReferentialUploadContainer label {
    margin: 0;
}

#uploadingSpinnerContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    opacity: 0.8;
    z-index: 1000;
}

.help-area {
    position: relative;
    cursor: pointer;
}

.help-area .info {
    position: absolute;
    visibility: hidden;
    width: max-content;
    background-color: white;
    box-shadow: .1em .1em .25em 0 rgba(0, 0, 0, .25);
    padding: .1em .5em;
    margin: .5em 0;
    z-index: 1;
}

.help-area:hover .info {
    visibility: visible;
}

#uploadingSpinnerContainer p {
    color: #fff;
    margin-bottom: 0;
    text-align: center;
}

.UploadTitle {
    padding-top: 1rem;
}

.UploadOvewrite {
    margin: .5em 0;
    padding: 0.5em;
}

.UploadInfo {
    padding: .5rem 0 2rem;
}

.UploadControlsContainer {
    display: grid;
    grid-template-columns: repeat(4, min-content) auto;
    grid-column-gap: 2vw;
    grid-row-gap: .5vw;
    align-items: center;
}

.UploadControlDesc {
    white-space: nowrap;
    margin-right: 1vw;
}

.FileInputButton {
    padding: 0!important;
}

.FileInputButton label {
    padding: .375rem .75rem
}