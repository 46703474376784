.evalpage-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.EvalContainer {
    flex-grow: 2;
    min-height: 80vh;
    overflow: auto;
}

.Evaluation {
    box-shadow: .1em .1em .25em 0 rgba(0, 0, 0, .25);
    padding: 1rem;
    margin: 2em;
    max-width: 70ch;
}

.autonomous .Evaluation {
    margin: 2em auto;
}

.EvalContainer h3 {
    padding: 3vh;
    text-align: center;
}

#BackButton {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 1.5rem 1.5rem .5rem 1.5rem;
    /* vertical-align: middle; */
    align-self: flex-start;
    z-index: 50;
}

#BackButton div {
    font-size: 1.2rem;
    padding-left: .1rem;
}

.ExerciceEvaluation {
    max-width: 50em;
    flex-grow: 1;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: center;
    vertical-align: middle;
    position: relative;
}

.autonomous .ExerciceEvaluation {
    max-width: min(80vw, 70em);
}

.evalpage-container .Loader {
    position: absolute;
    bottom: 40vh;
    left: 50%;
}

.ExerciceEvaluation table {
    table-layout: fixed;
    width: 100%;
    margin-top: 1em;
    display: flow-root;
    cursor: default;
}

.autonomous .ExerciceEvaluation table {
    width: 95%;
    margin-top: 6em;
}

.InBasket {
    width: 95% !important;
}

.ExerciceEvaluation table .Situation td {
    font-weight: bold;
    padding: .5vw 0;
}

.ExerciceEvaluation th:not(:first-child):not(.no-rotation) {
    transform: rotate(-70deg);
    word-wrap: break-word;
    max-width: 1px;
}

.ExerciceEvaluation th.no-rotation {
    width: auto;
    vertical-align: bottom;
}

.ExerciceEvaluation th {
    padding: .8em;
}

.ExerciceEvaluation td {
    padding: .25em 0 .25em .5em;
}

.ExerciceEvaluation td:last-child {
    padding-right: .5em;
}

.ExerciceEvaluation tbody tr:hover {
    background-color: #eeeeee;
}

.ExerciceEvaluation tbody .clickableRow:hover {
    cursor: pointer;
}

.InBasket tr:hover {
    cursor: pointer;
}

.ExerciceEvaluation input[type="checkbox"] {
    position: relative;
    width: 1.5em;
    height: 1.5em;
    color: black;
    border: 1px solid gray;
    border-radius: 4px;
    appearance: none;
    margin: .5vw 0 .2vw 0;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

.ExerciceEvaluation input[type="checkbox"]::before {
    position: absolute;
    content: '';
    display: block;
    top: 2px;
    left: 7px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}

.ExerciceEvaluation input[type="checkbox"]:enabled {
    cursor: pointer;
}

.ExerciceEvaluation input[type="checkbox"]:checked {
    color: white;
    border-color: var(--main-btn-color);
    background: var(--main-btn-color);
}

.ExerciceEvaluation input[type="checkbox"]:disabled {
    opacity: .3;
}

.ExerciceEvaluation input[type="checkbox"]:checked::before {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.ExerciceEvaluation table span {
    padding-left: 0;
    padding-right: 0;
}

.ExerciceEvaluation > * {
    grid-column: 1;
    grid-row: 1;
}

.Loader {
    margin: auto;
    z-index: 100;
}

.ExerciceEvaluation th {
    font-weight: bold;
}

.ButtonArea {
    margin: 2rem;
    display: flex;
    justify-content: center;
}

.CommentsArea {
    margin: 6em 1em 1em 4em;
    display: grid;
    padding: 1em;
    height: min-content;
    box-shadow: .1em .1em .25em 0 rgba(0, 0, 0, .25);
}

.CommentsArea textarea {
    resize: both;
    min-height: 10em;
    min-width: 15em;
    max-width: min(40vw, 30em);
    width: 20em;
    overflow: auto;
    padding: .5em;
    height: 20em;
}

.ButtonArea > * {
    margin: 0 1rem;
}

@media (orientation: portrait) {
    .evalpage-container {
        display: grid;
        grid-template-columns: 1fr;
        overflow: auto;
    }

    .EvalContainer {
        width: auto;
        min-height: 0;
    }

    .CommentsArea {
        margin: 1em auto;
    }

    .CommentsArea textarea {
        min-width: 18em;
        max-width: 35em;
        min-height: 5em;
        max-height: 25vh;
    }
}