#SeanceView .ButtonContainer {
    display: flex;
    justify-content: center;
    margin: 2vw auto 3vw;
}

.col {
    margin: 1vw;
}

#SeanceView .ButtonContainer>* {
    margin: 0 1rem;
}

#SeanceView.SeancePlanning {
    width: 100%;
}

#SeanceView .tab-content {
    border: 1px solid #dee2e6;
    padding-bottom: 15px;
}