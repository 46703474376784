#dataConsent {
    width: 100vw;
    z-index: 100;
    border: .08em solid black;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-width: 100vw;
    justify-content: space-between;
    background-color: #FFFFFF;
}

#dataConsent .dataPolicyLink {
    margin-left: 1em;
    color: #0000FF;
    cursor: pointer;
    text-decoration: underline;
}

#dataConsent .btn {
    margin: .25em;
}

#dataPolicyModal .modal-body {
    overflow-y: scroll;
}

#dataPolicyModal .modal-content {
    max-height: 90vh;
}

