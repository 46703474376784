.Timeline text {
    text-anchor: middle;
    alignment-baseline: middle;
}

.Timeline svg {
    box-shadow: 0 0 1em -.7em rgba(0,0,0, .5);
}

.Timeline .TimelineButtons {
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    margin-bottom: .25em;
}

.Timeline .TimelineButtons > * {
    cursor: pointer;
    margin: .25em;
}

.Timeline .TimelineButtons svg:first-of-type {
    grid-column: 3;
}

.Timeline .TimelineButtons > *:hover {
    background: rgba(241, 239, 239, 0.5);
}
