#header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--main-bg-color);
    z-index: 2;
}

#user-dropdown::after{
    content: none;
}