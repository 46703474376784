.sidebar {
    top: 0;
    left: 0;
    height: 100%;
    position: sticky;
    width: min-content;
    box-sizing: border-box;
    font-size: .7rem;
    background-color: var(--secondary-bg-color);
    z-index: 100;
}

.sidebar_item.parent {
    color: #90C84C;
    position: relative;
    padding: 0 .2rem;
}

.sidebar_item.children_container {
    display: none;
    position: absolute;
    left: 100%;
    z-index: 2;
    border: 1px solid var(--main-font-color);
}

.sidebar_item.child {
    white-space: nowrap;
    font-size: 1rem;
    padding: .2rem .5rem;
    box-sizing: border-box;
    background-color: var(--secondary-bg-color);
}

.sidebar_item .child:hover {
    background-color: var(--selected-bg-color);
}

.sidebar_item {
    cursor: pointer;
}

.sidebar_item:hover .children_container {
    display: block;
}

.sidebar_item * {
    box-sizing: border-box;
    margin: auto;
    text-align: center;
    justify-items: center;
    color: var(--secondary-font-color);
}

.sidebar_item.parent > .label {
    padding: 0 .6rem .5rem;
}

.sidebar_item.child > .label {
    padding-left: .2rem;
    justify-items: left;
}

.sidebar_item.parent:hover {
    background-color: var(--selected-bg-color);
}

.sidebar_item.parent > svg {
    display: block;
    margin-top: .5rem;
    fill: var(--main-bg-color);
    --img-width: 3rem;
    width: var(--img-width);
    height: var(--img-width);
}

.sidebar_item.child {
    display: flex;
}

.sidebar_item.child svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: var(--main-bg-color);
    margin: .1rem .2rem .1rem 0;
}