.ProfileContainer {
    box-shadow: 0 0 1em -.7em rgba(0, 0, 0, .5);
    margin: 4em auto;
    padding: 1.5rem;
}

.ProfileContainer h3 {
    margin: .2em auto .8em !important
}

.ProfileContainer .form-container {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.5em;
}

.ProfileContainer .form-container input, select {
    width: 100%;
}

.ProfileContainer .form-container input {
    padding: .1em .3em;
}

.ProfileContainer .form-container select {
    padding: .4em;
}

.ProfileContainer .form-container .password {
    grid-column: 1;
}

.ProfileContainer .buttons-container {
    position: relative;
}

.ProfileContainer button[type="submit"] {
    position: absolute;
    right: 0;
}


.userForm .form-area > * > * {
    margin: auto 1rem;
}

.error {
    color: var(--red);
    font-style: italic;
}

.account-management {
    box-shadow: 0 0 1em -.4em rgba(0, 0, 0, .5);
    padding: 1.5em;
    border: .1em solid var(--red);
}

.account-management h3 {
    padding-bottom: .5em;
}