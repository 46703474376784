.NotificationArea {
    position: absolute;
    right: 1rem;
    top: 4rem;
    z-index: 50;
}

.NotificationArea .toast-header strong, small {
    padding: 0 1vh;
}

.NotificationArea .toast-body {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.NotificationArea .toast-body a {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    color: var(--main-btn-color);
}