.defaultTable {
    width: 100%;
    white-space:pre-wrap;
    word-wrap:break-word;
}

.defaultTable, .defaultTable th, .defaultTable td {
    border: 1px solid var(--main-font-color);
    border-collapse: collapse;
}

.defaultTable tbody tr:hover {
    background-color: var(--tertiary-bg-color);
}

.paginationDisplay {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.tableRow{
    cursor: default;
}
